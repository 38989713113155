import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withFirebase } from '../Firebase'

const INITIAL_STATE = {
  loading: false,
  loadingBtn: false,
  totalItemsCount: 0,
  lastItem: null,
  showMoreBtn: true,
  products: [],
  searchKeyword: '',
  searchCategory: '',
  searchFilter: '',
  searchEnabled: false,
}

class Collections extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ...INITIAL_STATE,
      settings: JSON.parse(localStorage.getItem('settings')),
    }

    this.timer = null
  }

  componentDidMount() {
    document.title = 'Collections'

    this.setState({ loading: true })
    this.loadProducts(false, true)
  }

  loadProducts(loadmore, fromComponent) {
    const {
      settings,
      lastItem,
      searchKeyword,
      searchCategory,
      searchFilter,
      searchEnabled,
    } = this.state
    const self = this

    var pageLimit =
      settings && settings.perPageCollections ? parseInt(settings.perPageCollections) : 8

    this.setState({ loadingBtn: true })

    /* Load first */
    var productQuery = this.props.firebase.products()

    /* Search */
    if (searchEnabled) {
      /* If request is from a search (onChangeSearch();), we clear out the product list then load the new search results */
      /* We identify weather the trigger is from a search or a load more button click using "searchEnabled" state */
      this.setState({
        products: [],
        searchEnabled: false,
      })
    }
    if (searchKeyword) {
      productQuery = productQuery.where(
        'keywords',
        'array-contains',
        searchKeyword.toLowerCase()
      )
    }
    if (searchCategory) {
      productQuery = productQuery.where('category', '==', searchCategory)
    }

    switch (searchFilter) {
      case 'price-descening':
        productQuery = productQuery.orderBy('price', 'desc')
        break
      case 'price-ascending':
        productQuery = productQuery.orderBy('price')
        break
      case 'on-sale':
        productQuery = productQuery.where('salePrice', '>', 0)
        break
      default:
        productQuery = productQuery.orderBy('price', 'desc')
    }

    productQuery = productQuery.limit(pageLimit)

    /* If there's a last item set, we start the query after that item using startAfter() method */
    if (loadmore && lastItem) {
      productQuery = productQuery.startAfter(lastItem)
    }

    productQuery.onSnapshot((snapshot) => {
      /* The onSnapshot() method registers a continuous listener that triggers every time something has changed, use get() to only call it once (disable realtime) */
      let productChunk = []

      snapshot.docChanges().forEach(function (change) {
        if (change.type === 'added') {
          /* Add more items to the screen... */
          productChunk.push({ ...change.doc.data(), pid: change.doc.id })
        } else if (change.type === 'modified') {
          /* If there is a change in realtime... */
          /* Apply the modification to the item directly without changing the current item index. */
          self.setState({
            products: self.state.products.map((el) =>
              el.pid === change.doc.id ? { ...change.doc.data(), pid: change.doc.id } : el
            ),
          })
        }
      })

      this.setState((prevState) => ({
        products:
          prevState.products && fromComponent
            ? [...prevState.products, ...productChunk]
            : productChunk,
        loading: false,
        loadingBtn: false,
        lastItem: snapshot.docs[snapshot.docs.length - 1],
        showMoreBtn: productChunk.length < pageLimit ? false : true,
      }))
    })
  }

  onChangeSearch = (event) => {
    /* Save state but do not trigger firestore search not until user stops typing. */
    clearTimeout(this.timer)
    this.setState({ searchKeyword: event.target.value, searchEnabled: true })
    this.timer = setTimeout(this.loadProducts.bind(this), 500)
  }

  onChangeCategory = (event) => {
    clearTimeout(this.timer)
    this.setState({ searchCategory: event.target.value, searchEnabled: true })
    this.timer = setTimeout(this.loadProducts.bind(this), 100)
  }

  onChangeFilter = (event) => {
    clearTimeout(this.timer)
    this.setState({ searchFilter: event.target.value, searchEnabled: true })
    this.timer = setTimeout(this.loadProducts.bind(this), 100)
  }

  render() {
    const {
      products,
      loading,
      loadingBtn,
      settings,
      showMoreBtn,
      searchCategory,
      searchKeyword,
      searchFilter,
    } = this.state
    return (
      <div>
        <div className="container-fluid px-lg-3" style={{ backgroundColor: '#f2f2f2' }}>
          {loading ? (
            <div>Loading ...</div>
          ) : (
            <div className="row">
              <div className="col-xl-2 col-lg-3 py-3 bg-white">
                <div className="form-group">
                  <label className="font-weight-bold">Category:</label>
                  <select
                    className="form-control form-control-sm"
                    name="category"
                    onChange={this.onChangeCategory}
                    value={searchCategory}
                  >
                    <option value="">Show All</option>
                    {settings &&
                      settings.productCategories &&
                      settings.productCategories.map((cat, index) => (
                        <option key={index} value={cat.slag}>
                          {cat.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group">
                  <label className="font-weight-bold">Filter:</label>
                  <select
                    className="form-control form-control-sm"
                    name="category"
                    onChange={this.onChangeFilter}
                    value={searchFilter}
                  >
                    <option value="price-descening">Price Descening</option>
                    <option value="price-ascending">Price Ascending</option>
                    <option value="on-sale">On Sale Items</option>
                  </select>
                </div>
              </div>

              <div className="col-xl-10 col-lg-9">
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control w-50 border-0"
                    placeholder="Search for anything"
                    onChange={this.onChangeSearch}
                    value={searchKeyword}
                  />
                </div>

                <div className="row bg-white">
                  {products ? (
                    products.map((product, index) => (
                      <div className="col-6 col-lg-3 col-md-6 px-0" key={index}>
                        <div className="p-2 p-lg-3 border-bottom border-left">
                          <Link
                            to={{ pathname: '/products/view/' + product.pid }}
                            style={{ minHeight: '180px' }}
                            className="d-block"
                          >
                            <div className="text-center">
                              <img
                                src={`${
                                  product.featured_image
                                    ? product.featured_image
                                    : '/placeholder.jpg'
                                }`}
                                className="img-fluid mb-2"
                                alt={product.name}
                                style={{ maxHeight: '180px' }}
                              />
                            </div>
                            <div>
                              {product.name}
                              <div className="lead text-dark">
                                {product.salePrice > 0 ? (
                                  <span>
                                    <strike className="mr-2">
                                      {settings && settings.currencySymbol}{' '}
                                      {product.price}
                                    </strike>{' '}
                                    {settings && settings.currencySymbol}
                                    {product.salePrice}
                                  </span>
                                ) : (
                                  <span>
                                    {settings && settings.currencySymbol} {product.price}
                                  </span>
                                )}
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>No products</div>
                  )}
                </div>
                <div className="col text-center mb-3">
                  {showMoreBtn ? (
                    <button
                      className="btn btn-purple font-weight-bold btn-lg mt-3"
                      disabled={loadingBtn}
                      onClick={() => this.loadProducts(true, true)}
                    >
                      {loadingBtn ? <span>Loading...</span> : <span>Load More</span>}
                    </button>
                  ) : (
                    <div className="mt-3">
                      {products.length ? (
                        <span>End of result</span>
                      ) : (
                        <span>No results found</span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default withFirebase(Collections)
