/* Validate price, do not allow entering characters other than number and decimal */
export function isPriceKey(evt) {
  var charCode = evt.which ? evt.which : evt.keyCode
  if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
    evt.preventDefault()
  }
}

/* Validate spacing, must not be allowed to enter spaces */
export function noSpace(evt) {
  var charCode = evt.which ? evt.which : evt.keyCode
  if (charCode === 32) {
    evt.preventDefault()
  }
}

/* Generate keywords from a string - group of words such as a sentence */
export function getKeywords(string) {
  var combinationsArray = []
  var sArray = string.split(' ')
  var n = sArray.length
  for (var i = 0; i < n; i++) {
    for (var j = 0; j <= i; j++) {
      combinationsArray.push(
        sArray
          .slice(j, n - i + j)
          .join(' ')
          .toLowerCase()
      )
    }
  }

  return combinationsArray
}
