import React, { Component } from 'react'
import { compose } from 'recompose'
import { withFirebase } from '../../Firebase'
import { withAuthorization } from '../../Session'
import cogoToast from 'cogo-toast'
import * as ROLES from '../../../constants/roles'
import * as ROUTES from '../../../constants/routes'

const INITIAL_STATE = {
  loading: true,
  user: null,
  error: null,
}

class Users extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ...INITIAL_STATE,
    }
  }

  componentDidMount() {
    this.setState({ loading: true })

    let user_id = this.props.match.params.id

    this.props.firebase
      .user(user_id)
      .get()
      .then((snapshot) => {
        const userObject = snapshot.data()

        if (userObject) {
          document.title = userObject.firstName + ' ' + userObject.lastName

          this.setState({
            user: userObject,
            id: user_id,
            loading: false,
          })
        } else {
          this.props.history.push(ROUTES.NOT_FOUND)
        }
      })
  }

  onUpdateRole = (event) => {
    const { user } = this.state

    let isChecked = event.target.checked
    let updatedUser = user
    let itemIndex = updatedUser.roles.findIndex(
      (item) => event.target.name.toUpperCase() === item
    )

    if (isChecked === false && itemIndex > -1) {
      /* -1 means not existing in roles array */
      updatedUser.roles.splice(itemIndex, 1)
    } else if (isChecked === true && itemIndex < 0) {
      updatedUser.roles.push(event.target.name.toUpperCase())
    }

    this.setState({ user: updatedUser })
  }

  onSubmit = (event) => {
    event.preventDefault()

    const { user, id } = this.state

    /* Update user data in firebase */
    this.props.firebase
      .user(id)
      .set({ ...user }, { merge: true })
      .then(() => {
        cogoToast.success('User updated')
      })
      .catch((error) => {
        this.setState({ error })
      })
  }

  render() {
    const { loading, user, error } = this.state

    return (
      <div style={{ backgroundColor: '#f2f2f2' }}>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <React.Fragment>
            <nav className="navbar navbar-dark" style={{ backgroundColor: '#6200ee' }}>
              <span className="navbar-brand">
                {user.firstName} {user.lastName}
              </span>
            </nav>

            <div className="container-fluid py-3">
              <div className="card mb-3">
                <div className="card-body">
                  <strong>Roles:</strong>
                  <div className="ml-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultChecked={user.roles.includes('CUSTOMER') ? true : false}
                        name="customer"
                        id="customer"
                        onChange={this.onUpdateRole}
                      />
                      <label className="form-check-label" htmlFor="customer">
                        Customer
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultChecked={user.roles.includes('SELLER') ? true : false}
                        name="seller"
                        id="seller"
                        onChange={this.onUpdateRole}
                      />
                      <label className="form-check-label" htmlFor="seller">
                        Seller
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultChecked={user.roles.includes('ADMIN') ? true : false}
                        name="admin"
                        id="admin"
                        onChange={this.onUpdateRole}
                      />
                      <label className="form-check-label" htmlFor="admin">
                        Administrator
                      </label>
                    </div>
                  </div>

                  <button className="btn btn-dark mt-4" onClick={this.onSubmit}>
                    Save Changes
                  </button>

                  {error && (
                    <div className="alert alert-danger" role="alert">
                      {error.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}

const condition = (authUser) => authUser && authUser.roles.includes(ROLES.ADMIN)

export default compose(withAuthorization(condition), withFirebase)(Users)
