import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { PasswordForgetForm } from '../PasswordForget'
import PasswordChangeForm from '../PasswordChange'
import AccountBasicForm from './basic'
import { AuthUserContext, withAuthorization } from '../Session'
import { withFirebase } from '../Firebase'

const SIGN_IN_METHODS = [
  {
    id: 'password',
    provider: null,
  },
  {
    id: 'google.com',
    provider: 'googleProvider',
  },
  {
    id: 'facebook.com',
    provider: 'facebookProvider',
  },
  {
    id: 'twitter.com',
    provider: 'twitterProvider',
  },
]

const Account = () => (
  <AuthUserContext.Consumer>
    {(authUser) => (
      <div>
        <nav className="navbar navbar-dark" style={{ backgroundColor: '#6200ee' }}>
          <span className="navbar-brand">Account</span>
        </nav>

        <div className="container-fluid py-3" style={{ backgroundColor: '#f2f2f2' }}>
          <div className="card mb-3">
            <div className="card-body">
              <AccountBasicForm />
            </div>
          </div>

          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title">Forgot Password?</h5>
              <PasswordForgetForm />
            </div>
          </div>

          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title">Reset Password</h5>
              <PasswordChangeForm />
            </div>
          </div>

          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title">Sign In Methods</h5>
              <LoginManagement authUser={authUser} />
            </div>
          </div>
        </div>
      </div>
    )}
  </AuthUserContext.Consumer>
)

class LoginManagementBase extends Component {
  _isMounted = false

  constructor(props) {
    super(props)

    this.state = {
      activeSignInMethods: [],
      error: null,
    }
  }

  componentDidMount() {
    document.title = 'Account'

    this.fetchSignInMethods()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  fetchSignInMethods = () => {
    this._isMounted = true

    this.props.firebase.auth
      .fetchSignInMethodsForEmail(this.props.authUser.email)
      .then((activeSignInMethods) => {
        if (this._isMounted) {
          this.setState({ activeSignInMethods, error: null })
        }
      })
      .catch((error) => this.setState({ error }))
  }

  onSocialLoginLink = (provider) => {
    this.props.firebase.auth.currentUser
      .linkWithPopup(this.props.firebase[provider])
      .then(this.fetchSignInMethods)
      .catch((error) => this.setState({ error }))
  }

  onUnlink = (providerId) => {
    this.props.firebase.auth.currentUser
      .unlink(providerId)
      .then(this.fetchSignInMethods)
      .catch((error) => this.setState({ error }))
  }

  onDefaultLoginLink = (password) => {
    const credential = this.props.firebase.emailAuthProvider.credential(
      this.props.authUser.email,
      password
    )

    this.props.firebase.auth.currentUser
      .linkAndRetrieveDataWithCredential(credential)
      .then(this.fetchSignInMethods)
      .catch((error) => this.setState({ error }))
  }

  render() {
    const { activeSignInMethods, error } = this.state

    return (
      <div>
        <ul>
          {SIGN_IN_METHODS.map((signInMethod) => {
            const onlyOneLeft = activeSignInMethods.length === 1
            const isEnabled = activeSignInMethods.includes(signInMethod.id)

            return (
              <li key={signInMethod.id}>
                {signInMethod.id === 'password' ? (
                  <DefaultLoginToggle
                    onlyOneLeft={onlyOneLeft}
                    isEnabled={isEnabled}
                    signInMethod={signInMethod}
                    onLink={this.onDefaultLoginLink}
                    onUnlink={this.onUnlink}
                  />
                ) : (
                  <SocialLoginToggle
                    onlyOneLeft={onlyOneLeft}
                    isEnabled={isEnabled}
                    signInMethod={signInMethod}
                    onLink={this.onSocialLoginLink}
                    onUnlink={this.onUnlink}
                  />
                )}
              </li>
            )
          })}
        </ul>
        {error && error.message}
      </div>
    )
  }
}

const SocialLoginToggle = ({ onlyOneLeft, isEnabled, signInMethod, onLink, onUnlink }) =>
  isEnabled ? (
    <Link to="#" onClick={() => onUnlink(signInMethod.id)} disabled={onlyOneLeft}>
      Deactivate {signInMethod.id}
    </Link>
  ) : (
    <Link to="#" onClick={() => onLink(signInMethod.provider)}>
      Link {signInMethod.id}
    </Link>
  )

class DefaultLoginToggle extends Component {
  constructor(props) {
    super(props)

    this.state = { passwordOne: '', passwordTwo: '' }
  }

  onSubmit = (event) => {
    event.preventDefault()

    this.props.onLink(this.state.passwordOne)
    this.setState({ passwordOne: '', passwordTwo: '' })
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { onlyOneLeft, isEnabled, signInMethod, onUnlink } = this.props

    const { passwordOne, passwordTwo } = this.state

    const isInvalid = passwordOne !== passwordTwo || passwordOne === ''

    return isEnabled ? (
      <Link to="/" onClick={() => onUnlink(signInMethod.id)} disabled={onlyOneLeft}>
        Deactivate {signInMethod.id}
      </Link>
    ) : (
      <form onSubmit={this.onSubmit}>
        <input
          name="passwordOne"
          value={passwordOne}
          onChange={this.onChange}
          type="password"
          placeholder="New Password"
          autoComplete="off"
          className="form-control mb-2"
        />
        <input
          name="passwordTwo"
          value={passwordTwo}
          onChange={this.onChange}
          type="password"
          placeholder="Confirm New Password"
          autoComplete="off"
          className="form-control mb-2"
        />

        <button disabled={isInvalid} type="submit" className="btn btn-dark mb-2">
          Link {signInMethod.id}
        </button>
      </form>
    )
  }
}

const LoginManagement = withFirebase(LoginManagementBase)

const condition = (authUser) => !!authUser // "!!authUser" is shorthand for: "authUser != null"

export default withAuthorization(condition)(Account)
