import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import SignOutButton from '../SignOut'
import * as ROUTES from '../../constants/routes'
import * as ROLES from '../../constants/roles'
import { AuthUserContext } from '../Session'
import { withFirebase } from '../Firebase'

class Navigation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      authUser: JSON.parse(localStorage.getItem('authUser')),
      cartItemsCount: 0,
    }
  }

  componentDidMount() {
    const { authUser } = this.state

    if (authUser) {
      this.unsubscribeCartItems = this.props.firebase
        .cartItems()
        .doc(authUser.uid)
        .onSnapshot((snapshotCart) => {
          /* The onSnapshot() method registers a continuous listener that triggers every time something has changed, use get() to only call it once (disable realtime) */
          this.setState({
            cartItemsCount: snapshotCart.data()
              ? Object.keys(snapshotCart.data()).length
              : 0,
          })
        })
    }
  }

  /* On unmount, we remove the listener to avoid memory leaks from using the same reference with the off() method: */
  componentWillUnmount() {
    this.unsubscribeCartItems()
  }

  render() {
    return (
      <AuthUserContext.Consumer>
        {(authUser) =>
          authUser ? (
            <NavigationAuth
              authUser={authUser}
              cartItemsCount={this.state.cartItemsCount}
            />
          ) : (
            <NavigationNonAuth />
          )
        }
      </AuthUserContext.Consumer>
    )
  }
}

const NavigationAuth = ({ authUser, cartItemsCount }) => (
  <div className="list-group accordion border-right" id="navAccordion">
    <NavLink
      exact
      className="list-group-item list-group-item-action border-0"
      to={ROUTES.COLLECTIONS}
    >
      <i className="material-icons align-middle mr-3">apps</i>
      Collections
    </NavLink>
    {authUser.roles.includes(ROLES.CUSTOMER) && (
      <NavLink
        className="list-group-item list-group-item-action border-0"
        to={ROUTES.CART}
      >
        <i className="material-icons align-middle mr-3">shopping_cart</i>
        Cart ({cartItemsCount})
      </NavLink>
    )}

    <div>
      <NavLink
        to="#"
        className="list-group-item list-group-item-action border-0 collapsed"
        data-toggle="collapse"
        data-target="#profile"
        aria-expanded="false"
      >
        <i className="material-icons align-middle mr-3">person</i>
        Profile
        <i className="material-icons float-right">expand_more</i>
      </NavLink>

      <div
        className="list-group list-group-flush collapse"
        id="profile"
        data-parent="#navAccordion"
      >
        <NavLink
          className="list-group-item list-group-item-action border-0 bg-light"
          to={ROUTES.ACCOUNT}
        >
          <i className="material-icons align-middle mx-3">panorama_fish_eye</i>
          Account
        </NavLink>
        <NavLink
          exact
          className="list-group-item list-group-item-action border-0 bg-light"
          to={ROUTES.CUSTOMER_ORDERS}
        >
          <i className="material-icons align-middle mx-3">panorama_fish_eye</i>
          Orders
        </NavLink>
      </div>
    </div>

    {authUser.roles.includes(ROLES.SELLER) && (
      <div>
        <NavLink
          to="#"
          className="list-group-item list-group-item-action border-0 collapsed"
          data-toggle="collapse"
          data-target="#seller"
          aria-expanded="false"
        >
          <i className="material-icons align-middle mr-3">store</i>
          Seller
          <i className="material-icons float-right">expand_more</i>
        </NavLink>

        <div
          className="list-group list-group-flush collapse"
          id="seller"
          data-parent="#navAccordion"
        >
          <NavLink
            exact
            className="list-group-item list-group-item-action border-0 bg-light"
            to={ROUTES.PRODUCTS}
          >
            <i className="material-icons align-middle mx-3">panorama_fish_eye</i>
            Products
          </NavLink>
          <NavLink
            exact
            className="list-group-item list-group-item-action border-0 bg-light"
            to={ROUTES.CREATE_PRODUCT}
          >
            <i className="material-icons align-middle mx-3">panorama_fish_eye</i>
            New Product
          </NavLink>
          <NavLink
            exact
            className="list-group-item list-group-item-action border-0 bg-light"
            to={ROUTES.SELLER_ORDERS}
          >
            <i className="material-icons align-middle mx-3">panorama_fish_eye</i>
            Orders
          </NavLink>
        </div>
      </div>
    )}
    {authUser.roles.includes(ROLES.ADMIN) && (
      <div>
        <NavLink
          to="#"
          className="list-group-item list-group-item-action border-0 collapsed"
          data-toggle="collapse"
          data-target="#admin"
          aria-expanded="false"
        >
          <i className="material-icons align-middle mr-3">timeline</i>
          Admin
          <i className="material-icons float-right">expand_more</i>
        </NavLink>

        <div
          className="list-group list-group-flush collapse"
          id="admin"
          data-parent="#navAccordion"
        >
          <NavLink
            to={ROUTES.ADMIN_USERS}
            className="list-group-item list-group-item-action border-0 bg-light"
          >
            <i className="material-icons align-middle mx-3">panorama_fish_eye</i>
            Users
          </NavLink>
          <NavLink
            to={ROUTES.ADMIN_SETTINGS_GENERAL}
            className="list-group-item list-group-item-action border-0 bg-light"
          >
            <i className="material-icons align-middle mx-3">panorama_fish_eye</i>
            Settings
          </NavLink>
        </div>
      </div>
    )}
    <div className="border"></div>
    <SignOutButton />
  </div>
)

const NavigationNonAuth = () => (
  <div className="list-group borderless">
    <NavLink
      exact
      className="list-group-item list-group-item-action border-0"
      to={ROUTES.COLLECTIONS}
    >
      <i className="material-icons align-middle mr-3">inbox</i>
      Collections
    </NavLink>
    <NavLink
      className="list-group-item list-group-item-action border-0"
      to={ROUTES.SIGN_IN}
    >
      <i className="material-icons align-middle mr-3">person</i>
      Sign In
    </NavLink>
  </div>
)

export default withFirebase(Navigation)
