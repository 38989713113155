import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
}

class Firebase {
  constructor() {
    app.initializeApp(config)

    this.fieldValue = app.firestore.FieldValue
    this.emailAuthProvider = app.auth.EmailAuthProvider
    this.auth = app.auth() /* Initialize auth */
    this.storage = app.storage() /* Initialize storage */
    this.db = app.firestore() /* Initialize the firestore API */

    this.googleProvider = new app.auth.GoogleAuthProvider()
    this.facebookProvider = new app.auth.FacebookAuthProvider()
    this.twitterProvider = new app.auth.TwitterAuthProvider()
  }

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then((snapshot) => {
            if (snapshot.exists) {
              const dbUser = snapshot.data()

              /* default empty roles */
              if (!dbUser.roles) {
                dbUser.roles = []
              }

              /* merge auth and db user */
              authUser = {
                uid: authUser.uid,
                email: authUser.email,
                ...dbUser,
              }

              next(authUser)
            }
          })
      } else {
        fallback()
      }
    })

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password)

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider)

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider)

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider)

  doSignOut = () => this.auth.signOut()

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email)

  doPasswordUpdate = (password) => this.auth.currentUser.updatePassword(password)

  currentUser = () => this.auth.currentUser

  // *** DB API ***

  db = () => this.db

  fieldValue = () => this.fieldValue

  cartItems = () => this.db.collection('cart') /* Get a reference to cart */

  order = (oid) =>
    this.db.doc(`orders/${oid}`) /* Get a reference to a order by identifier (oid) */
  orders = () => this.db.collection('orders') /* Get a reference to all orders */

  product = (pid) =>
    this.db.doc(`products/${pid}`) /* Get a reference to a product by identifier (pid) */
  products = () => this.db.collection('products') /* Get a reference to all products */

  settings = () => this.db.collection('settings') /* Get a reference to all settings */

  storage = () => this.storage() /* Get a reference to storage */

  user = (uid) =>
    this.db.doc(`users/${uid}`) /* Get a reference to a user by identifier (uid) */
  users = () => this.db.collection('users') /* Get a reference to all users */
}

export default Firebase
