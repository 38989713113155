import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import { withFirebase } from '../Firebase'
import { withAuthorization } from '../Session'
import * as ROLES from '../../constants/roles'
import cogoToast from 'cogo-toast'

const INITIAL_STATE = {
  loading: false,
  loadingBtn: false,
  totalItemsCount: 0,
  lastItem: null,
  showMoreBtn: true,
  products: [],
  searchKeyword: '',
  searchCategory: '',
  searchFilter: '',
  searchEnabled: false,
}

class Products extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ...INITIAL_STATE,
      authUser: JSON.parse(localStorage.getItem('authUser')),
      settings: JSON.parse(localStorage.getItem('settings')),
    }

    this.timer = null
  }

  componentDidMount() {
    document.title = 'Products'

    this.setState({ loading: true })
    this.loadProducts(false, true)
  }

  /* On unmount, we remove the listener to avoid memory leaks from using the same reference with the off() method: */
  componentWillUnmount() {
    this.unsubscribeProducts()
  }

  loadProducts(loadmore, fromComponent) {
    const {
      settings,
      lastItem,
      searchKeyword,
      searchCategory,
      searchFilter,
      searchEnabled,
    } = this.state
    const self = this

    var pageLimit =
      settings && settings.perPageSellerProducts
        ? parseInt(settings.perPageSellerProducts)
        : 5

    this.setState({ loadingBtn: true })

    /* Load first */
    var productQuery = this.props.firebase
      .products()
      .where('author', '==', this.state.authUser.uid)

    /* Search */
    if (searchEnabled) {
      /* If request is from a search (onChangeSearch();), we clear out the product list then load the new search results */
      /* We identify weather the trigger is from a search or a load more button click using "searchEnabled" state */
      this.setState({
        products: [],
        searchEnabled: false,
      })
    }
    if (searchKeyword) {
      productQuery = productQuery.where(
        'keywords',
        'array-contains',
        searchKeyword.toLowerCase()
      )
    }
    if (searchCategory) {
      productQuery = productQuery.where('category', '==', searchCategory)
    }

    switch (searchFilter) {
      case 'price-descening':
        productQuery = productQuery.orderBy('price', 'desc')
        break
      case 'price-ascending':
        productQuery = productQuery.orderBy('price')
        break
      case 'on-sale':
        productQuery = productQuery.where('salePrice', '>', 0)
        break
      default:
        productQuery = productQuery.orderBy('price', 'desc')
    }

    productQuery = productQuery.limit(pageLimit)

    /* If there's a last item set, we start the query after that item using startAfter() method */
    if (loadmore && lastItem) {
      productQuery = productQuery.startAfter(lastItem)
    }

    this.unsubscribeProducts = productQuery.onSnapshot((snapshot) => {
      /* The onSnapshot() method registers a continuous listener that triggers every time something has changed, use get() to only call it once (disable realtime) */
      let productChunk = []

      snapshot.docChanges().forEach(function (change) {
        if (change.type === 'added') {
          /* Add more items to the screen... */
          productChunk.push({ ...change.doc.data(), pid: change.doc.id })
        } else if (change.type === 'modified') {
          /* If there is a change in realtime... */
          /* Apply the modification to the item directly without changing the current item index. */
          self.setState({
            products: self.state.products.map((el) =>
              el.pid === change.doc.id ? { ...change.doc.data(), pid: change.doc.id } : el
            ),
          })
        } else if (change.type === 'removed') {
          let updatedProductList = Object.values(self.state.products) /* Clone it first */
          let itemIndex = updatedProductList.findIndex(
            (item) => change.doc.id === item.pid
          ) /* Get the index of the item we want to delete */

          /* Remove item from the cloned cart state */
          updatedProductList.splice(itemIndex, 1)

          /* Update state to remove item from screen */
          self.setState({
            products: updatedProductList,
          })
        }
      })

      this.setState((prevState) => ({
        products:
          prevState.products && fromComponent
            ? [...prevState.products, ...productChunk]
            : productChunk,
        loading: false,
        loadingBtn: false,
        lastItem: snapshot.docs[snapshot.docs.length - 1],
        showMoreBtn: productChunk.length < pageLimit ? false : true,
      }))
    })
  }

  onDelete(id) {
    if (id && window.confirm('Are you sure you want to delete this item' + id + '?')) {
      let product = this.props.firebase.product(id)

      product
        .get()
        .then((snapshot) => {
          let storageRef = this.props.firebase.storage

          /* Delete product images */
          if (snapshot.data().images && snapshot.data().images.length) {
            snapshot.data().images.forEach((image) => {
              storageRef.ref().child(`${image.path}`).delete()
            })
          }

          /* Delete the product */
          product
            .delete()
            .then(() => {
              /* No need to updat state here because real time will handle that */
              cogoToast.success('Product Deleted')
            })
            .catch((error) => {
              this.setState({ error })
            })
        })
        .catch((error) => {
          this.setState({ error })
        })
    }
  }

  onChangeSearch = (event) => {
    /* Save state but do not trigger firestore search not until user stops typing. */
    clearTimeout(this.timer)
    this.setState({ searchKeyword: event.target.value, searchEnabled: true })
    this.timer = setTimeout(this.loadProducts.bind(this), 500)
  }

  onChangeCategory = (event) => {
    clearTimeout(this.timer)
    this.setState({ searchCategory: event.target.value, searchEnabled: true })
    this.timer = setTimeout(this.loadProducts.bind(this), 100)
  }

  onChangeFilter = (event) => {
    clearTimeout(this.timer)
    this.setState({ searchFilter: event.target.value, searchEnabled: true })
    this.timer = setTimeout(this.loadProducts.bind(this), 100)
  }

  render() {
    const {
      products,
      loading,
      loadingBtn,
      settings,
      showMoreBtn,
      searchCategory,
      searchKeyword,
      searchFilter,
    } = this.state
    return (
      <div style={{ backgroundColor: '#f2f2f2' }}>
        {loading ? (
          <div>Loading ...</div>
        ) : (
          <React.Fragment>
            <nav className="navbar navbar-dark" style={{ backgroundColor: '#6200ee' }}>
              <span className="navbar-brand">Products</span>
            </nav>

            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-2 col-lg-3 p-3 bg-white">
                  <div className="form-group">
                    <label className="font-weight-bold">Category:</label>
                    <select
                      className="form-control form-control-sm"
                      name="category"
                      onChange={this.onChangeCategory}
                      value={searchCategory}
                    >
                      <option value="">Show All</option>
                      {settings &&
                        settings.productCategories &&
                        settings.productCategories.map((cat, index) => (
                          <option key={index} value={cat.slag}>
                            {cat.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="font-weight-bold">Filter:</label>
                    <select
                      className="form-control form-control-sm"
                      name="category"
                      onChange={this.onChangeFilter}
                      value={searchFilter}
                    >
                      <option value="price-descening">Price Descening</option>
                      <option value="price-ascending">Price Ascending</option>
                      <option value="on-sale">On Sale Items</option>
                    </select>
                  </div>
                </div>

                <div className="col-xl-10 col-lg-9">
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      className="form-control w-50 border-0"
                      placeholder="Search for anything"
                      onChange={this.onChangeSearch}
                      value={searchKeyword}
                    />
                  </div>

                  <div className="table-responsive">
                    <table className="table res-tbl">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>SKU</th>
                          <th>Stock</th>
                          <th>Price</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.length > 0 &&
                          products.map((product) => (
                            <tr key={product.pid}>
                              <td data-label="Product">
                                <Link
                                  to={{ pathname: '/products/update/' + product.pid }}
                                  className="d-block d-lg-flex"
                                  style={{ maxWidth: '300px' }}
                                >
                                  <div className="float-none float-lg-left mr-3 mb-2 mb-lg-0">
                                    <img
                                      src={`${
                                        product.featured_image
                                          ? product.featured_image
                                          : '/placeholder.jpg'
                                      }`}
                                      width="80"
                                      alt={product.name}
                                    />
                                  </div>
                                  <div className="d-flex align-self-center">
                                    {product.name}
                                  </div>
                                </Link>
                              </td>
                              <td data-label="SKU">{product.sku}</td>
                              <td data-label="Stock">{product.stock}</td>
                              <td data-label="Price">
                                {product.salePrice > 0 ? (
                                  <span>
                                    <strike className="mr-2">
                                      {settings && settings.currencySymbol}{' '}
                                      {product.price}
                                    </strike>{' '}
                                    {settings && settings.currencySymbol}
                                    {product.salePrice}
                                  </span>
                                ) : (
                                  <span>
                                    {settings && settings.currencySymbol} {product.price}
                                  </span>
                                )}
                              </td>
                              <td data-label="Action">
                                <Link
                                  to={{ pathname: '/products/update/' + product.pid }}
                                  className="btn btn-sm"
                                >
                                  <span className="material-icons align-middle">
                                    edit
                                  </span>
                                </Link>{' '}
                                &nbsp;
                                <Link
                                  to="#"
                                  onClick={() => this.onDelete(product.pid)}
                                  className="btn btn-sm"
                                >
                                  <span className="material-icons align-middle">
                                    delete
                                  </span>
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>

                    <div className="col text-center mb-3">
                      {showMoreBtn ? (
                        <button
                          className="btn btn-purple font-weight-bold btn-lg mt-3"
                          disabled={loadingBtn}
                          onClick={() => this.loadProducts(true, true)}
                        >
                          {loadingBtn ? <span>Loading...</span> : <span>Load More</span>}
                        </button>
                      ) : (
                        <div className="mt-3">
                          {products.length ? (
                            <span>End of result</span>
                          ) : (
                            <span>No results found</span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}

const condition = (authUser) => authUser && authUser.roles.includes(ROLES.SELLER)

export default compose(withAuthorization(condition), withFirebase)(Products)
