import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import AuthUserContext from './context'
import { withFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'

/* The "condition" here is derived from components that implements it, example in: Account/index.js" */
const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      /* onAuthUserListener can be found in Firebase/firebase.js */
      this.listener = this.props.firebase.onAuthUserListener(
        (authUser) => {
          if (!condition(authUser)) {
            this.props.history.push(ROUTES.SIGN_IN)
          }
        },
        () => this.props.history.push(ROUTES.SIGN_IN)
      )
    }

    /* 
		We also want to avoid memory leaks that lead to performance issues, so we’ll 
			remove the listener if the component unmounts.
		*/

    componentWillUnmount() {
      this.listener()
    }

    render() {
      return (
        /* Avoid showing the protected page before the redirect happens */
        <AuthUserContext.Consumer>
          {(authUser) => (condition(authUser) ? <Component {...this.props} /> : null)}
        </AuthUserContext.Consumer>
      )
    }
  }

  return compose(withRouter, withFirebase)(WithAuthorization)
}

export default withAuthorization
