import React from 'react'
import AuthUserContext from './context'
import { withFirebase } from '../Firebase'

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        authUser: JSON.parse(localStorage.getItem('authUser')),
      }
    }

    /* 
		 Set auth state if logged-in, null if not logged-in. 
		 We check if we're logged-in by using firebase. 
		 onAuthStateChanged() is realtime - It is called when a user signs up, signs in, and signs out.
		 If there is a change in auth, all components in this app that is depending on the 
			authUser state will also adjust - remember this file withAuthentication.js is a higher order component, it 
			broadcasts the state down to all components. 
		*/
    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        (authUser) => {
          localStorage.setItem('authUser', JSON.stringify(authUser))
          this.setState({ authUser })
        },
        () => {
          localStorage.removeItem('authUser')
          this.setState({ authUser: null })
        }
      )
    }

    /* 
		We also want to avoid memory leaks that lead to performance issues, so we’ll 
			remove the listener if the component unmounts.
		*/
    componentWillUnmount() {
      this.listener()
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      )
    }
  }

  return withFirebase(WithAuthentication)
}

export default withAuthentication
