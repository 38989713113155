import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import { withFirebase } from '../Firebase'
import { withAuthorization } from '../Session'
import * as ROLES from '../../constants/roles'

const INITIAL_STATE = {
  loading: false,
  loadingBtn: false,
  totalItemsCount: 0,
  lastItem: null,
  showMoreBtn: true,
  orders: [],
  searchFilter: '',
}

class SellerOrders extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ...INITIAL_STATE,
      authUser: JSON.parse(localStorage.getItem('authUser')),
    }

    this.timer = null
  }

  componentDidMount() {
    document.title = 'Orders'

    this.setState({ loading: true })
    this.loadSellerOrders(false, true)
  }

  /* On unmount, we remove the listener to avoid memory leaks from using the same reference with the off() method: */
  componentWillUnmount() {
    this.unsubscribeOrders()
  }

  loadSellerOrders(loadmore, fromComponent) {
    const { lastItem, searchFilter, authUser } = this.state
    const self = this

    var pageLimit = 5

    this.setState({ loadingBtn: true })

    /* Load first */
    var dataQuery = this.props.firebase.orders()

    switch (searchFilter) {
      case 'ASC':
        dataQuery = dataQuery.orderBy('createdAt')
        break
      case 'DESC':
        dataQuery = dataQuery.orderBy('createdAt', 'desc')
        break
      default:
        dataQuery = dataQuery.orderBy('createdAt')
    }

    dataQuery = dataQuery.limit(pageLimit)

    /* If there's a last item set, we start the query after that item using startAfter() method */
    if (loadmore && lastItem) {
      dataQuery = dataQuery.startAfter(lastItem)
    }

    this.unsubscribeOrders = dataQuery
      .where('sellers', 'array-contains', authUser.uid)
      .onSnapshot((snapshot) => {
        /* The onSnapshot() method registers a continuous listener that triggers every time something has changed, use get() to only call it once (disable realtime) */
        let orderChunk = []

        snapshot.docChanges().forEach(function (change) {
          let orderOthers = change.doc
            .data()
            .others.find((item) => authUser.uid === item.sellerID)

          if (change.type === 'added') {
            /* Add more items to the screen... */
            orderChunk.push({
              ...change.doc.data(),
              oid: change.doc.id,
              orderStatus: orderOthers.status,
            })
          } else if (change.type === 'modified') {
            /* If there is a change in realtime... */
            /* Apply the modification to the item directly without changing the current item index. */
            self.setState({
              orders: self.state.orders.map((el) =>
                el.oid === change.doc.id
                  ? {
                      ...change.doc.data(),
                      oid: change.doc.id,
                      orderStatus: orderOthers.status,
                    }
                  : el
              ),
            })
          }
        })

        this.setState((prevState) => ({
          orders:
            prevState.orders && fromComponent
              ? [...prevState.orders, ...orderChunk]
              : orderChunk,
          loading: false,
          loadingBtn: false,
          lastItem: snapshot.docs[snapshot.docs.length - 1],
          showMoreBtn: orderChunk.length < pageLimit ? false : true,
        }))
      })
  }

  onChangeFilter = (event) => {
    clearTimeout(this.timer)
    this.setState({ searchFilter: event.target.value })
    this.timer = setTimeout(this.loadSellerOrders.bind(this), 100)
  }

  render() {
    const { orders, loading, loadingBtn, showMoreBtn, searchFilter } = this.state
    return (
      <div style={{ backgroundColor: '#f2f2f2' }}>
        {loading ? (
          <div>Loading ...</div>
        ) : (
          <React.Fragment>
            <nav className="navbar navbar-dark" style={{ backgroundColor: '#6200ee' }}>
              <span className="navbar-brand">Orders</span>
            </nav>
            <div className="container-fluid px-lg-3">
              <div className="row">
                <div className="col-xl-2 col-lg-3 py-3 bg-white">
                  <div className="form-group">
                    <label className="font-weight-bold">Date Added:</label>
                    <select
                      className="form-control form-control-sm"
                      name="category"
                      onChange={this.onChangeFilter}
                      value={searchFilter}
                    >
                      <option value="ASC">Ascending</option>
                      <option value="DESC">Descening</option>
                    </select>
                  </div>
                </div>

                <div className="col-xl-10 col-lg-9">
                  <div className="table-responsive">
                    <table className="table res-tbl">
                      <thead>
                        <tr>
                          <th>Order</th>
                          <th>Payment Option</th>
                          <th>Status</th>
                          <th>Date Created</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orders.length > 0 &&
                          orders.map((order) => (
                            <tr key={order.oid}>
                              <td data-label="Order">
                                <Link
                                  to={{ pathname: '/orders/seller/view/' + order.oid }}
                                  className="font-weight-bold"
                                >
                                  Order from {order.customerEmail}
                                </Link>
                              </td>
                              <td data-label="Payment Option">{order.paymentOption}</td>
                              <td data-label="Status">
                                <button className="btn btn-outline-dark btn-sm" disabled>
                                  {order.orderStatus}
                                </button>
                              </td>
                              <td data-label="Date Created">
                                {new Date(order.createdAt).toLocaleDateString('en-US')}{' '}
                                {new Date(order.createdAt).toLocaleTimeString()}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>

                    <div className="col text-center mb-3">
                      {showMoreBtn ? (
                        <button
                          className="btn btn-purple font-weight-bold btn-lg mt-3"
                          disabled={loadingBtn}
                          onClick={() => this.loadSellerOrders(true, true)}
                        >
                          {loadingBtn ? <span>Loading...</span> : <span>Load More</span>}
                        </button>
                      ) : (
                        <div className="mt-3">
                          {orders.length ? (
                            <span>End of result</span>
                          ) : (
                            <span>No results found</span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}

const condition = (authUser) => authUser && authUser.roles.includes(ROLES.SELLER)

export default compose(withAuthorization(condition), withFirebase)(SellerOrders)
