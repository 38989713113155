import React, { Component } from 'react'

class App extends Component {
  render() {
    return (
      <div>
        <nav className="navbar navbar-dark" style={{ backgroundColor: '#6200ee' }}>
          <span className="navbar-brand">Home</span>
        </nav>

        <div className="container-fluid py-3" style={{ backgroundColor: '#f2f2f2' }}>
          <div className="card mb-3">
            <div className="card-body">
              <h3>You are now logged-in</h3>
              <h3>My Wish List</h3>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default App
