import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'
import cogoToast from 'cogo-toast'
import parse from 'html-react-parser'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'

const INITIAL_STATE = {
  shippingType: '',
  error: null,
  loading: true,
  upload_files: [],
  authUser: null,
  cart: [],
  seller: null,
}

const shippingOptionsObject = {
  shippingStandard: 'Standard Shipping',
  shippingExpedited: 'Expedited Shipping',
  shippingSameDayDelivery: 'Same Day Delivery',
  'Free shipping': 'Free shipping',
  'Local pickup': 'Local pickup',
}

class ViewProduct extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...INITIAL_STATE,
      authUser: JSON.parse(localStorage.getItem('authUser')),
      settings: JSON.parse(localStorage.getItem('settings')),
    }
  }

  componentDidMount() {
    const { authUser } = this.state

    document.title = 'Loading...'

    let product_id = this.props.match.params.id

    if (product_id) {
      /* Fetch product from our Firebase realtime database  */
      /* this.props.firebase.product() -- the "product" is taken from firebase.js  */
      this.unsubscribeProduct = this.props.firebase
        .product(product_id)
        .onSnapshot((snapshot) => {
          /* Use once() to only call it once (disable realtime) */
          const productObject = snapshot.data()

          if (productObject) {
            document.title = productObject.name

            this.setState({
              product: productObject,
              id: product_id,
              loading: false,
            })

            this.props.firebase
              .user(productObject.author)
              .onSnapshot((snapshotSeller) => {
                this.setState({ seller: snapshotSeller.data() })
              })
          } else {
            this.props.history.push(ROUTES.NOT_FOUND)
          }
        })

      if (authUser) {
        /* Listen to realtime cart changes */
        this.unsubscribeCartItems = this.props.firebase
          .cartItems()
          .doc(authUser.uid)
          .onSnapshot((snapshotCart) => {
            if (snapshotCart.data()) {
              this.setState({ cart: snapshotCart.data() })
            } else {
              this.setState({ cart: [] })
            }
          })
      }
    }
  }

  /* On unmount, we remove the listener to avoid memory leaks from using the same reference with the off() method: */
  componentWillUnmount() {
    this.unsubscribeProduct && this.unsubscribeProduct()
    this.unsubscribeCartItems && this.unsubscribeCartItems()
  }

  onAddToCart() {
    const { id, product, authUser, cart, shippingType } = this.state

    if (authUser) {
      let is_existing =
        Object.keys(cart).length &&
        Object.values(cart).find(
          (item) => id === item.id
        ) /* Check if item already exists in cart from state */
      if (!is_existing) {
        let newItem = {
          id: id,
          quantity: 1,
          sellerID: product.author,
          purchasePrice: product.salePrice > 0 ? product.salePrice : product.price,
          shippingType: shippingType,
          shippingValue:
            product[shippingType] > 0
              ? product[shippingType]
              : 0 /* Get shipping option value base on key */,
        }
        let updatedCart = Object.values(cart) /* Clone it first */
        let cartRef = this.props.firebase.cartItems()

        /* Push new cart item */
        updatedCart.push(newItem)

        /* Set updated cart in firebase, no need to use setState because we already have a realtime cart listener in the componentDidMount() */
        cartRef
          .doc(authUser.uid)
          .set(Object.assign({}, updatedCart))
          .then(() => {
            cogoToast.success('Added to cart')
          })
      }
    } else {
      this.props.history.push(ROUTES.SIGN_IN)
    }
  }

  onRemoveFromCart() {
    const { id, authUser, cart } = this.state

    let is_existing =
      Object.keys(cart).length &&
      Object.values(cart).find(
        (item) => id === item.id
      ) /* Check if item exists in cart from state */
    if (is_existing) {
      let updatedCart = Object.values(cart) /* Clone it first */
      let itemIndex = updatedCart.findIndex(
        (item) => id === item.id
      ) /* Get the index of the item we want to delete */
      let cartRef = this.props.firebase.cartItems()

      /* Remove item from the cloned cart state */
      updatedCart.splice(itemIndex, 1)

      /* Set updated cart in firebase, no need to use setState because we already have a realtime cart listener in the componentDidMount() */
      cartRef
        .doc(authUser.uid)
        .set(Object.assign({}, updatedCart))
        .then(() => {
          cogoToast.success('Removed from cart')
        })
    }
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const {
      id,
      seller,
      product,
      loading,
      error,
      cart,
      settings,
      shippingType,
    } = this.state

    let imagesSlider = []
    if (product && product.images && product.images.length) {
      product.images.forEach((image, _index, array) => {
        let newImage = { original: image.url, thumbnail: image.url }
        imagesSlider.push(newImage) /* Push new image to the imagesSlider state */
      })
    }

    return (
      <div style={{ backgroundColor: '#f2f2f2' }}>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div className="container-fluid py-3">
            <div className="row">
              <div className="col-lg-5">
                {imagesSlider.length ? (
                  <ImageGallery items={imagesSlider} defaultImage="/placeholder.jpg" />
                ) : (
                  <img src="/placeholder.jpg" width="100%" alt="Not available" />
                )}
              </div>
              <div className="col-lg-7">
                <div className="card mb-3">
                  <div className="card-body">
                    <h4 className="text-secondary">{product.name}</h4>
                    <h3>
                      {product.salePrice > 0 ? (
                        <span>
                          <strike className="mr-2 text-muted">
                            {settings && settings.currencySymbol} {product.price}
                          </strike>{' '}
                          {settings && settings.currencySymbol} {product.salePrice}
                        </span>
                      ) : (
                        <span>
                          {settings && settings.currencySymbol} {product.price}
                        </span>
                      )}
                    </h3>
                    <p>{product.excerpt}</p>
                    <div className="mb-3">
                      {product.sku && <div>SKU: {product.sku}</div>}
                      <div>Category: {product.category}</div>
                      <div>On Stock: {product.stock ? product.stock : 0}</div>

                      <div className="media p-2 bg-light w-50 mt-3">
                        <Link to={'/seller/' + product.author} className="mr-2">
                          {seller && seller.avatarUrl ? (
                            <img src={seller.avatarUrl} alt="Avatar" height="80" />
                          ) : (
                            <img src="/placeholder-avatar.jpg" alt="Avatar" height="80" />
                          )}
                        </Link>
                        <div className="media-body">
                          <h6 className="pt-2">
                            <Link to={'/seller/' + product.author}>
                              {seller &&
                                (seller.storeName
                                  ? seller.storeName
                                  : seller.firstName + ' ' + seller.lastName)}
                            </Link>
                          </h6>
                          <p>
                            Joined:{' '}
                            {seller &&
                              new Date(seller.createdAt).toLocaleDateString('en-US')}
                          </p>
                        </div>
                      </div>
                    </div>

                    <hr />

                    {product.stock > 0 ? (
                      <div>
                        {Object.keys(cart).length &&
                        Object.values(cart).find((item) => id === item.id) ? (
                          <div>
                            <button
                              className="btn btn-purple rounded-0 font-weight-bold px-4 py-2 mr-2"
                              onClick={() => {
                                this.onRemoveFromCart()
                              }}
                            >
                              Remove From Cart
                            </button>
                            <Link
                              to="/cart"
                              className="btn btn-purple rounded-0 font-weight-bold px-4 py-2"
                            >
                              View Cart
                            </Link>
                          </div>
                        ) : (
                          <div>
                            <div className="mb-4">
                              <select
                                name="shippingType"
                                onChange={this.onChange}
                                value={shippingType}
                                className="form-control ml-1 w-auto d-inline-block"
                              >
                                <option value="">Select Shipping</option>
                                {product.shippingMethod === 'Shipping options' ? (
                                  <React.Fragment>
                                    {product.shippingStandard && (
                                      <option value="shippingStandard">
                                        {settings && settings.currencySymbol}{' '}
                                        {product.shippingStandard} on{' '}
                                        {shippingOptionsObject['shippingStandard']}
                                      </option>
                                    )}
                                    {product.shippingExpedited && (
                                      <option value="shippingExpedited">
                                        {settings && settings.currencySymbol}{' '}
                                        {product.shippingExpedited} on{' '}
                                        {shippingOptionsObject['shippingExpedited']}
                                      </option>
                                    )}
                                    {product.shippingSameDayDelivery && (
                                      <option value="shippingSameDayDelivery">
                                        {settings && settings.currencySymbol}{' '}
                                        {product.shippingSameDayDelivery} on{' '}
                                        {shippingOptionsObject['shippingSameDayDelivery']}
                                      </option>
                                    )}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <option value={product.shippingMethod}>
                                      {product.shippingMethod}
                                    </option>
                                  </React.Fragment>
                                )}
                              </select>
                            </div>
                            <button
                              className="btn btn-purple rounded-0 font-weight-bold px-4 py-2"
                              onClick={() => {
                                /* Do not allow add to cart if option selected is not in list of available shipping options */
                                if (shippingType in shippingOptionsObject) {
                                  this.onAddToCart()
                                }
                              }}
                              disabled={shippingType === ''}
                            >
                              Add To Cart
                            </button>
                            {shippingType === '' && (
                              <em className="text-danger ml-2">
                                Select a shipping option
                              </em>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <button
                        className="btn btn-purple rounded-0 font-weight-bold px-4 py-2"
                        disabled
                      >
                        Sold Out
                      </button>
                    )}

                    {/*<hr />
										{Object.keys(cart).length ? 
											<div className="carousel-inner" >
												{Object.values(cart).map((item, index) => (
													<div key={index}>
														<div>{item.id}</div>
													</div>
												))}
											</div>
											:
											<div>Empty Cart</div>
										}*/}

                    <hr />
                    <div>{parse(product.description)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {error && <div>{error.message}</div>}
      </div>
    )
  }
}

/* We use compose() because each higher order component bellow dont depend on each other
   so instead of doing:
		withRouter(withFirebase(ViewProduct));
   we just organize it like bellow:
*/
const ViewProductPage = compose(withRouter, withFirebase)(ViewProduct)

export default ViewProductPage
