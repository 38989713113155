import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import { withFirebase } from '../../Firebase'
import { withAuthorization } from '../../Session'
import * as ROLES from '../../../constants/roles'
import cogoToast from 'cogo-toast'

const INITIAL_STATE = {
  loading: false,
  loadingBtn: false,
  totalItemsCount: 0,
  lastItem: null,
  showMoreBtn: true,
  users: [],
  searchKeyword: '',
  searchFilter: '',
  searchEnabled: false,
}

class Users extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ...INITIAL_STATE,
    }

    this.timer = null
  }

  componentDidMount() {
    document.title = 'Admin - Users'

    this.setState({ loading: true })
    this.loadUsers(false, true)
  }

  /* On unmount, we remove the listener to avoid memory leaks from using the same reference with the off() method: */
  componentWillUnmount() {
    this.unsubscribeUsers()
  }

  loadUsers(loadmore, fromComponent) {
    const { lastItem, searchKeyword, searchFilter, searchEnabled } = this.state
    const self = this

    var pageLimit = 5

    this.setState({ loadingBtn: true })

    /* Load first */
    var userQuery = this.props.firebase.users()

    /* Search */
    if (searchEnabled) {
      /* If request is from a search (onChangeSearch();), we clear out the user list then load the new search results */
      /* We identify weather the trigger is from a search or a load more button click using "searchEnabled" state */
      this.setState({
        users: [],
        searchEnabled: false,
      })
    }
    if (searchKeyword) {
      userQuery = userQuery.where(
        'keywords',
        'array-contains',
        searchKeyword.toLowerCase()
      )
    }

    switch (searchFilter) {
      case 'ASC':
        userQuery = userQuery.orderBy('createdAt')
        break
      case 'DESC':
        userQuery = userQuery.orderBy('createdAt', 'desc')
        break
      default:
        userQuery = userQuery.orderBy('createdAt')
    }

    userQuery = userQuery.limit(pageLimit)

    /* If there's a last item set, we start the query after that item using startAfter() method */
    if (loadmore && lastItem) {
      userQuery = userQuery.startAfter(lastItem)
    }

    this.unsubscribeUsers = userQuery.onSnapshot((snapshot) => {
      /* The onSnapshot() method registers a continuous listener that triggers every time something has changed, use get() to only call it once (disable realtime) */
      let userChunk = []

      snapshot.docChanges().forEach(function (change) {
        if (change.type === 'added') {
          /* Add more users to the screen... */
          userChunk.push({ ...change.doc.data(), uid: change.doc.id })
        } else if (change.type === 'modified') {
          /* If there is a change in realtime... */
          /* Apply the modification to the user directly without changing the current user index. */
          self.setState({
            users: self.state.users.map((el) =>
              el.uid === change.doc.id ? { ...change.doc.data(), uid: change.doc.id } : el
            ),
          })
        } else if (change.type === 'removed') {
          let updatedUserList = Object.values(self.state.users) /* Clone it first */
          let userIndex = updatedUserList.findIndex(
            (user) => change.doc.id === user.uid
          ) /* Get the index of the user we want to delete */

          /* Remove user from the cloned user state */
          updatedUserList.splice(userIndex, 1)

          /* Update state to remove user from screen */
          self.setState({
            users: updatedUserList,
          })
        }
      })

      this.setState((prevState) => ({
        users:
          prevState.users && fromComponent
            ? [...prevState.users, ...userChunk]
            : userChunk,
        loading: false,
        loadingBtn: false,
        lastItem: snapshot.docs[snapshot.docs.length - 1],
        showMoreBtn: userChunk.length < pageLimit ? false : true,
      }))
    })
  }

  onDelete(id) {
    if (
      id &&
      window.confirm(
        'Are you sure you want to delete this user' +
          id +
          '? Take note that all data of this user will also be deleted, which includes Products, Orders and Cart Items .'
      )
    ) {
      let products = this.props.firebase.products()
      let storageRef = this.props.firebase.storage
      let orders = this.props.firebase.orders()
      let user = this.props.firebase.user(id)

      /* Delete products */
      products
        .where('author', '==', id)
        .get()
        .then((snapshotProducts) => {
          if (snapshotProducts.docs.length) {
            /* Delete product images */
            snapshotProducts.docs.forEach((doc) => {
              if (doc.data().images.length) {
                doc.data().images.forEach((image) => {
                  storageRef.ref().child(`${image.path}`).delete()
                })
              }
            })

            snapshotProducts.docs.forEach((doc) => {
              products.doc(doc.id).delete()
            })
          }
        })

      /* Delete Cart Items */
      this.props.firebase.cartItems().doc(id).delete()

      /* Delete Orders */
      orders
        .where('customerID', '==', id)
        .get()
        .then((snapshotOrders) => {
          if (snapshotOrders.docs.length) {
            snapshotOrders.docs.forEach((doc) => {
              orders.doc(doc.id).delete()
            })
          }
        })
      /* Delete user */
      user.get().then((snapshotUser) => {
        /* Delete user avatar */
        if (snapshotUser.data().avatarPath) {
          storageRef.ref().child(`${snapshotUser.data().avatarPath}`).delete()
        }

        user
          .delete()
          .then(() => {
            /* No need to update state here because real time will handle that */
            cogoToast.success('User Deleted')
          })
          .catch((error) => {
            this.setState({ error })
          })
      })
    }
  }

  onChangeSearch = (event) => {
    /* Save state but do not trigger firestore search not until user stops typing. */
    clearTimeout(this.timer)
    this.setState({ searchKeyword: event.target.value, searchEnabled: true })
    this.timer = setTimeout(this.loadUsers.bind(this), 500)
  }

  onChangeFilter = (event) => {
    clearTimeout(this.timer)
    this.setState({ searchFilter: event.target.value, searchEnabled: true })
    this.timer = setTimeout(this.loadUsers.bind(this), 100)
  }

  render() {
    const {
      users,
      loading,
      loadingBtn,
      showMoreBtn,
      searchKeyword,
      searchFilter,
    } = this.state
    return (
      <div style={{ backgroundColor: '#f2f2f2' }}>
        {loading ? (
          <div>Loading ...</div>
        ) : (
          <React.Fragment>
            <nav className="navbar navbar-dark" style={{ backgroundColor: '#6200ee' }}>
              <span className="navbar-brand">Users</span>
            </nav>
            <div className="container-fluid px-lg-3">
              <div className="row">
                <div className="col-xl-2 col-lg-3 py-3 bg-white">
                  <div className="form-group">
                    <label className="font-weight-bold">Date Added:</label>
                    <select
                      className="form-control form-control-sm"
                      name="category"
                      onChange={this.onChangeFilter}
                      value={searchFilter}
                    >
                      <option value="ASC">Ascending</option>
                      <option value="DESC">Descening</option>
                    </select>
                  </div>
                </div>

                <div className="col-xl-10 col-lg-9">
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      className="form-control w-50 border-0"
                      placeholder="Search for anything"
                      onChange={this.onChangeSearch}
                      value={searchKeyword}
                    />
                  </div>

                  <div className="table-responsive">
                    <table className="table res-tbl">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.length > 0 &&
                          users.map((user) => (
                            <tr key={user.uid}>
                              <td data-label="Name">
                                <Link to={'/admin/users/' + user.uid}>
                                  {user.firstName} {user.lastName}
                                </Link>
                              </td>
                              <td data-label="Email">{user.email}</td>
                              <td data-label="Phone">{user.phone}</td>
                              <td data-label="Action">
                                <Link
                                  to="#"
                                  onClick={() => this.onDelete(user.uid)}
                                  className="btn btn-sm"
                                >
                                  <span className="material-icons align-middle">
                                    delete
                                  </span>
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>

                    <div className="col text-center mb-3">
                      {showMoreBtn ? (
                        <button
                          className="btn btn-purple font-weight-bold btn-lg mt-3"
                          disabled={loadingBtn}
                          onClick={() => this.loadUsers(true, true)}
                        >
                          {loadingBtn ? <span>Loading...</span> : <span>Load More</span>}
                        </button>
                      ) : (
                        <div className="mt-3">
                          {users.length ? (
                            <span>End of result</span>
                          ) : (
                            <span>No results found</span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}

const condition = (authUser) => authUser && authUser.roles.includes(ROLES.ADMIN)

export default compose(withAuthorization(condition), withFirebase)(Users)
