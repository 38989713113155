import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import { withFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'

const INITIAL_STATE = {
  loading: false,
  loadingBtn: false,
  totalItemsCount: 0,
  lastItem: null,
  showMoreBtn: true,
  products: [],
  searchKeyword: '',
  searchCategory: '',
  searchFilter: '',
  searchEnabled: false,
  seller: null,
}

class SellerAccount extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ...INITIAL_STATE,
      settings: JSON.parse(localStorage.getItem('settings')),
    }

    this.timer = null
  }

  componentDidMount() {
    document.title = 'Seller'

    this.setState({ loading: true })

    this.props.firebase.user(this.props.match.params.id).onSnapshot((snapshot) => {
      let sellerObject = snapshot.data()

      /* Check if seller exists and has a "SELLER" role */
      if (sellerObject && sellerObject.roles.indexOf('SELLER')) {
        /* Set seller information in state, then load seller products */
        this.setState({ seller: sellerObject }, this.loadProducts(false, true))

        document.title =
          'Seller - ' + sellerObject.firstName + ' ' + sellerObject.lastName
      } else {
        /* Show 404 page if seller does not exist */
        this.props.history.push(ROUTES.NOT_FOUND)
      }
    })
  }

  /* On unmount, we remove the listener to avoid memory leaks from using the same reference with the off() method: */
  componentWillUnmount() {
    this.unsubscribeProducts && this.unsubscribeProducts()
  }

  loadProducts(loadmore, fromComponent) {
    const {
      settings,
      lastItem,
      searchKeyword,
      searchCategory,
      searchFilter,
      searchEnabled,
    } = this.state
    const self = this

    var pageLimit =
      settings && settings.perPageSellerProducts
        ? parseInt(settings.perPageSellerProducts)
        : 8

    this.setState({ loadingBtn: true })

    /* Load first */
    var productQuery = this.props.firebase
      .products()
      .where('author', '==', this.props.match.params.id)

    /* Search */
    if (searchEnabled) {
      /* If request is from a search (onChangeSearch();), we clear out the product list then load the new search results */
      /* We identify weather the trigger is from a search or a load more button click using "searchEnabled" state */
      this.setState({
        products: [],
        searchEnabled: false,
      })
    }
    if (searchKeyword) {
      productQuery = productQuery.where(
        'keywords',
        'array-contains',
        searchKeyword.toLowerCase()
      )
    }
    if (searchCategory) {
      productQuery = productQuery.where('category', '==', searchCategory)
    }

    switch (searchFilter) {
      case 'price-descening':
        productQuery = productQuery.orderBy('price', 'desc')
        break
      case 'price-ascending':
        productQuery = productQuery.orderBy('price')
        break
      case 'on-sale':
        productQuery = productQuery.where('salePrice', '>', 0)
        break
      default:
        productQuery = productQuery.orderBy('price', 'desc')
    }

    productQuery = productQuery.limit(pageLimit)

    /* If there's a last item set, we start the query after that item using startAfter() method */
    if (loadmore && lastItem) {
      productQuery = productQuery.startAfter(lastItem)
    }

    this.unsubscribeProducts = productQuery.onSnapshot((snapshot) => {
      /* The onSnapshot() method registers a continuous listener that triggers every time something has changed, use get() to only call it once (disable realtime) */
      let productChunk = []

      snapshot.docChanges().forEach(function (change) {
        if (change.type === 'added') {
          /* Add more items to the screen... */
          productChunk.push({ ...change.doc.data(), pid: change.doc.id })
        } else if (change.type === 'modified') {
          /* If there is a change in realtime... */
          /* Apply the modification to the item directly without changing the current item index. */
          self.setState({
            products: self.state.products.map((el) =>
              el.pid === change.doc.id ? { ...change.doc.data(), pid: change.doc.id } : el
            ),
          })
        }
      })

      this.setState((prevState) => ({
        products:
          prevState.products && fromComponent
            ? [...prevState.products, ...productChunk]
            : productChunk,
        loading: false,
        loadingBtn: false,
        lastItem: snapshot.docs[snapshot.docs.length - 1],
        showMoreBtn: productChunk.length < pageLimit ? false : true,
      }))
    })
  }

  onDelete(id) {
    if (id && window.confirm('Are you sure you want to delete this item' + id + '?')) {
      this.props.firebase
        .product(id)
        .delete()
        .catch((error) => {
          this.setState({ error })
        })
    }
  }

  onChangeSearch = (event) => {
    /* Save state but do not trigger firestore search not until user stops typing. */
    clearTimeout(this.timer)
    this.setState({ searchKeyword: event.target.value, searchEnabled: true })
    this.timer = setTimeout(this.loadProducts.bind(this), 500)
  }

  onChangeCategory = (event) => {
    clearTimeout(this.timer)
    this.setState({ searchCategory: event.target.value, searchEnabled: true })
    this.timer = setTimeout(this.loadProducts.bind(this), 100)
  }

  onChangeFilter = (event) => {
    clearTimeout(this.timer)
    this.setState({ searchFilter: event.target.value, searchEnabled: true })
    this.timer = setTimeout(this.loadProducts.bind(this), 100)
  }

  render() {
    const {
      products,
      seller,
      loading,
      loadingBtn,
      settings,
      showMoreBtn,
      searchCategory,
      searchKeyword,
      searchFilter,
    } = this.state

    return (
      <div style={{ backgroundColor: '#f2f2f2' }}>
        {loading ? (
          <div>Loading ...</div>
        ) : (
          <React.Fragment>
            <div className="container-fluid">
              <div className="row bg-white border-bottom p-3 mb-3">
                <div className="col-12">
                  <div className="float-left mr-2">
                    {seller && seller.avatarUrl ? (
                      <img src={seller.avatarUrl} alt="Avatar" height="80" />
                    ) : (
                      <img src="/placeholder-avatar.jpg" alt="Avatar" height="80" />
                    )}
                  </div>
                  <h5>
                    {seller &&
                      (seller.storeName
                        ? seller.storeName
                        : seller.firstName + ' ' + seller.lastName)}
                  </h5>
                  <p className="mb-0">
                    Joied:{' '}
                    {seller && new Date(seller.createdAt).toLocaleDateString('en-US')}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-2 col-lg-3 p-3 bg-white border-top">
                  <div className="form-group">
                    <label className="font-weight-bold">Category:</label>
                    <select
                      className="form-control form-control-sm"
                      name="category"
                      onChange={this.onChangeCategory}
                      value={searchCategory}
                    >
                      <option value="">Show All</option>
                      {settings &&
                        settings.productCategories.map((cat, index) => (
                          <option key={index} value={cat.slag}>
                            {cat.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="font-weight-bold">Filter:</label>
                    <select
                      className="form-control form-control-sm"
                      name="category"
                      onChange={this.onChangeFilter}
                      value={searchFilter}
                    >
                      <option value="price-descening">Price Descening</option>
                      <option value="price-ascending">Price Ascending</option>
                      <option value="on-sale">On Sale Items</option>
                    </select>
                  </div>
                </div>

                <div className="col-xl-10 col-lg-9">
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      className="form-control w-50 border-0"
                      placeholder="Search for anything"
                      onChange={this.onChangeSearch}
                      value={searchKeyword}
                    />
                  </div>

                  <div className="row bg-white">
                    {products ? (
                      products.map((product, index) => (
                        <div className="col-6 col-lg-3 col-md-6 px-0" key={index}>
                          <div className="p-2 p-lg-3 border-bottom border-left">
                            <Link
                              to={{ pathname: '/products/view/' + product.pid }}
                              style={{ minHeight: '180px' }}
                              className="d-block"
                            >
                              <div className="text-center">
                                <img
                                  src={`${
                                    product.featured_image
                                      ? product.featured_image
                                      : '/placeholder.jpg'
                                  }`}
                                  className="img-fluid mb-2"
                                  alt={product.name}
                                  style={{ maxHeight: '180px' }}
                                />
                              </div>
                              <div>
                                {product.name}
                                <div className="lead text-dark">
                                  {product.salePrice > 0 ? (
                                    <span>
                                      <strike className="mr-2">
                                        {settings && settings.currencySymbol}{' '}
                                        {product.price}
                                      </strike>{' '}
                                      {settings && settings.currencySymbol}
                                      {product.salePrice}
                                    </span>
                                  ) : (
                                    <span>
                                      {settings && settings.currencySymbol}{' '}
                                      {product.price}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>No products</div>
                    )}
                  </div>

                  <div className="col text-center mb-3">
                    {showMoreBtn ? (
                      <button
                        className="btn btn-purple font-weight-bold btn-lg mt-3"
                        disabled={loadingBtn}
                        onClick={() => this.loadProducts(true, true)}
                      >
                        {loadingBtn ? <span>Loading...</span> : <span>Load More</span>}
                      </button>
                    ) : (
                      <div className="mt-3">
                        {products.length ? (
                          <span>End of result</span>
                        ) : (
                          <span>No results found</span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}

export default compose(withFirebase)(SellerAccount)
