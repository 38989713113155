import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'

const PasswordForgetPage = () => (
  <div>
    <nav className="navbar navbar-dark" style={{ backgroundColor: '#6200ee' }}>
      <span className="navbar-brand">Forgot Password</span>
    </nav>
    <div className="container-fluid py-3" style={{ backgroundColor: '#f2f2f2' }}>
      <div className="card mb-3">
        <div className="card-body">
          <PasswordForgetForm />
        </div>
      </div>
    </div>
  </div>
)

const INITIAL_STATE = {
  email: '',
  error: null,
}

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }

  componentDidMount() {
    document.title = 'Forgot Password'
  }

  onSubmit = (event) => {
    const { email } = this.state

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE })
      })
      .catch((error) => {
        this.setState({ error })
      })

    event.preventDefault()
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { email, error } = this.state

    const isInvalid = email === ''

    return (
      <form onSubmit={this.onSubmit}>
        {error && <div>{error.message}</div>}

        <div className="form-group">
          <label>Email Address *</label>
          <input
            name="email"
            value={this.state.email}
            onChange={this.onChange}
            type="text"
            className="form-control w-25"
          />
        </div>

        <button disabled={isInvalid} type="submit" className="btn btn-dark">
          Reset My Password
        </button>
      </form>
    )
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
)

export default PasswordForgetPage

const PasswordForgetForm = withFirebase(PasswordForgetFormBase)

export { PasswordForgetForm, PasswordForgetLink }
