import React from 'react'
import SettingsContext from './context'
import { withFirebase } from '../Firebase'

const withSettings = (Component) => {
  class WithSettings extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        settings: JSON.parse(localStorage.getItem('settings')),
      }
    }

    /*
		 If there is a change in settings, all components in this app that is depending on the 
			settings state will also adjust - remember this file withSettings.js is a higher order component, it 
			broadcasts the state down to all components. 
		*/
    componentDidMount() {
      this.props.firebase
        .settings()
        .doc('general')
        .get()
        .then((snapshot) => {
          if (snapshot.data()) {
            localStorage.setItem('settings', JSON.stringify(snapshot.data()))
            this.setState({ settings: snapshot.data() })
          } else {
            localStorage.removeItem('settings')
            this.setState({ settings: null })
          }
        })
    }

    render() {
      return (
        <SettingsContext.Provider value={this.state.settings}>
          <Component {...this.props} />
        </SettingsContext.Provider>
      )
    }
  }

  return withFirebase(WithSettings)
}

export default withSettings
