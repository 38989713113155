import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withFirebase } from '../Firebase'
import { withAuthorization } from '../Session'
import cogoToast from 'cogo-toast'
import PaypalButton from '../Paypal'

const PAYPAL_CLIENT = {
  sandbox: process.env.REACT_APP_PAYPAL_CLIENT_ID_SANDBOX,
  production: process.env.REACT_APP_PAYPAL_CLIENT_ID_PRODUCTION,
}

const PAYPAL_ENV =
  process.env.REACT_APP_PAYPAL_ENV === 'production' ? 'production' : 'sandbox'

const INITIAL_STATE = {
  error: null,
  loading: true,
  authUser: null,
  billing: null,
  paymentOption: '',
  customerNotes: '',
  cart: [],
}

class Checkout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...INITIAL_STATE,
      authUser: JSON.parse(localStorage.getItem('authUser')),
      settings: JSON.parse(localStorage.getItem('settings')),
    }
  }

  componentDidMount() {
    document.title = 'Checkout'

    const { authUser, cart } = this.state
    const self = this

    if (authUser && cart) {
      /* This will also be triggered when new items are added to or removed from cart  */
      self.unsubscribeCartItems = self.props.firebase
        .cartItems()
        .doc(authUser.uid)
        .onSnapshot((snapshot) => {
          let updatedCart = [] /* Set empty array cart by default */

          if (snapshot.data() && Object.keys(snapshot.data()).length) {
            /* Loop through list of cart item IDs  */
            Object.values(snapshot.data()).forEach(function (snapshotCart, index) {
              /* Query the items based on item ID */
              self.unsubscribeProduct = self.props.firebase
                .product(snapshotCart.id)
                .onSnapshot((snapshotProduct) => {
                  /* If we receive a change on details of our product, we first check if it exists in our current cart state */
                  let is_existing = updatedCart.find(
                    (item) => snapshotProduct.id === item.id
                  )
                  if (is_existing) {
                    /* If existing, we find the position (index) of this item in our current cart state */
                    let itemIndex = updatedCart.findIndex(
                      (item) => snapshotProduct.id === item.id
                    )
                    /* Then we remove the item from the current cart state because we will append the updated item in our next code bellow (updatedCart.push())*/
                    updatedCart.splice(itemIndex, 1)
                  }

                  /* Append to cart array to reload entire cart item list */
                  updatedCart.push({ ...snapshotCart, ...snapshotProduct.data() })
                  self.setState({
                    cart: updatedCart,
                    loading: false,
                  }) /* !!!! setState is running multiple times here, figure out how to detect when child_added completed*/
                })
            })
          } else {
            self.setState({ cart: [], loading: false })
          }
        })

      /* Get billing user, we use firebase instead of authUser from localStorage so we can listen to 
			   any data changes on the account page, like if they decide to change their address. */
      self.unsubscribeBilling = self.props.firebase
        .user(authUser.uid)
        .onSnapshot((snapshot) => {
          self.setState({ billing: snapshot.data() })
        })
    } else {
      self.setState({ loading: false })
    }
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onPlaceOrder = () => {
    if (window.confirm('Are you sure you want to proceed placing the order?')) {
      this.processOrder('Pending')
    }
  }

  processOrder(status) {
    const { authUser, paymentOption, customerNotes, billing } = this.state
    const self = this

    let ordersRef = this.props.firebase.orders()
    let cartRef = this.props.firebase.cartItems()

    /* Get current cart contents */
    cartRef
      .doc(authUser.uid)
      .get()
      .then((snapshotCart) => {
        /* Insert initial order data */
        let orderDoc = ordersRef.doc()
        orderDoc
          .set({
            customerID: authUser.uid,
            customerEmail: authUser.email,
            paymentOption: paymentOption,
            customerNotes: customerNotes,
            billing: {
              firstName: billing.firstName,
              lastName: billing.lastName,
              phone: billing.phone,
              address: billing.address,
              city: billing.city,
              zip: billing.zip,
              state: billing.state,
              country: billing.country,
            },
            createdAt: new Date().getTime(),
            isCancelled: false,
          })
          .then(() => {
            let updatedCart = []
            let sellers = []
            let others = []
            Object.values(snapshotCart.data()).forEach(function (cartItem, index) {
              /* Attach order ID to each cart item array */

              updatedCart.push({ ...cartItem, orderID: orderDoc.id })

              /* Create an array containing ids of seller owners, this will be used for retreiving seller orders later on. */
              if (!sellers.includes(cartItem.sellerID)) {
                sellers.push(cartItem.sellerID)

                /* Add other information per seller. */
                others.push({ sellerID: cartItem.sellerID, status: status })
              }

              /* Subtract stock from the items */
              self.props.firebase
                .product(cartItem.id)
                .update({
                  stock: self.props.firebase.fieldValue.increment(-cartItem.quantity),
                })
            })

            /* Update the order by attaching the updated cart items and sellers. */
            ordersRef
              .doc(orderDoc.id)
              .update({
                cart: updatedCart,
                others: others,
                sellers: sellers,
              })
              .then(() => {
                /* Delete all cart items of this user. */
                cartRef
                  .doc(authUser.uid)
                  .delete()
                  .then(() => {
                    self.props.history.push('/checkout/success/' + orderDoc.id)
                  })
                  .catch((error) => {
                    this.setState({ error })
                  })
              })
              .catch((error) => {
                this.setState({ error })
              })

            cogoToast.success('Order submitted')
          })
          .catch((error) => {
            this.setState({ error })
          })
      })
  }

  /* On unmount, we remove the listener to avoid memory leaks from using the same reference with the off() method: */
  componentWillUnmount() {
    this.unsubscribeCartItems()
    this.unsubscribeBilling()
    this.unsubscribeProduct && this.unsubscribeProduct()
  }

  render() {
    const { error, loading, cart, paymentOption, billing, settings } = this.state
    const isInvalid =
      paymentOption === '' ||
      !billing.firstName ||
      !billing.lastName ||
      !billing.phone ||
      !billing.address ||
      !billing.city ||
      !billing.zip ||
      !billing.state ||
      !billing.country
    const hasOutOfStock =
      Object.keys(cart).length && Object.values(cart).find((item) => item.stock === 0)
        ? 1
        : 0

    const onSuccess = (payment) => {
      this.processOrder('Completed')
    }

    const onError = (error) => {
      cogoToast.error(error.message, { hideAfter: 10 })
    }

    const onCancel = (data) => {
      cogoToast.warn('Payment cancelled')
    }

    const shippingOptionsObject = {
      shippingStandard: 'Standard Shipping',
      shippingExpedited: 'Expedited Shipping',
      shippingSameDayDelivery: 'Same Day Delivery',
    }

    return (
      <div style={{ backgroundColor: '#f2f2f2' }}>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div className="container-fluid py-3">
            <div className="row">
              <div className="col-lg-8">
                <div className="mb-3">
                  {error && (
                    <div className="alert alert-danger">Error: {error.message}</div>
                  )}

                  <h4 className="mb-3">Your Order</h4>
                  <div className="table-responsive">
                    <table className="table res-tbl">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Shipping</th>
                          <th className="text-right">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cart.length ? (
                          cart.map((product, index) => (
                            <tr key={index}>
                              <td data-label="Product">
                                <Link
                                  to={{ pathname: '/products/view/' + product.id }}
                                  className="d-block d-lg-flex"
                                  style={{ maxWidth: '300px' }}
                                >
                                  <div className="mr-3 mb-2 mb-lg-0">
                                    <img
                                      src={`${
                                        product.featured_image
                                          ? product.featured_image
                                          : '/placeholder.jpg'
                                      }`}
                                      width="80"
                                      alt={product.name}
                                    />
                                  </div>
                                  <div className="d-inline-block text-dark">
                                    <div>
                                      {product.name} <span className="mx-2">x</span>{' '}
                                      <span className="font-weight-bold">
                                        {product.quantity}
                                      </span>
                                    </div>
                                    {!product.stock > 0 && (
                                      <div className="text-danger font-weight-bold">
                                        Out of stock
                                      </div>
                                    )}
                                  </div>
                                </Link>
                              </td>
                              <td data-label="Shipping">
                                {product.shippingType in shippingOptionsObject ? (
                                  <span>
                                    +{settings && settings.currencySymbol}{' '}
                                    {product.shippingValue * product.quantity} on{' '}
                                    {shippingOptionsObject[product.shippingType]}
                                  </span>
                                ) : (
                                  product.shippingType
                                )}
                              </td>
                              <td data-label="Total" className="text-right">
                                {settings && settings.currencySymbol}{' '}
                                {product.purchasePrice * product.quantity +
                                  product.quantity * product.shippingValue}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>Checkout is empty</td>
                          </tr>
                        )}
                        <tr className="font-weight-bold text-right">
                          <td colSpan="2">Total:</td>
                          <td>
                            {settings && settings.currencySymbol + ' '}
                            {cart.length
                              ? cart
                                  .reduce(
                                    (sum, i) =>
                                      (sum +=
                                        i.quantity * i.purchasePrice +
                                        i.quantity * i.shippingValue),
                                    0
                                  )
                                  .toFixed(2)
                              : 0}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <Link
                    to="/cart"
                    className="btn btn-purple font-weight-bold btn-lg mt-3"
                  >
                    Back to Cart
                  </Link>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card mb-3">
                  <div className="card-body">
                    <h4 className="mb-3">Billing Details:</h4>

                    {billing &&
                    billing.firstName &&
                    billing.lastName &&
                    billing.phone &&
                    billing.address &&
                    billing.city &&
                    billing.zip &&
                    billing.state &&
                    billing.country ? (
                      <div>
                        <strong>Full Name:</strong> {billing.firstName} {billing.lastName}{' '}
                        <br />
                        <strong>Phone:</strong> {billing.phone} <br />
                        <strong>Address:</strong> {billing.address}, {billing.city},{' '}
                        {billing.zip}, {billing.state}, {billing.country}
                      </div>
                    ) : (
                      <div className="alert alert-warning" role="alert">
                        <p>
                          Your account is missing some information that are required for
                          placing an order.{' '}
                        </p>
                        <ul>
                          <li>First Name and Last Name</li>
                          <li>Phone Number</li>
                          <li>Addrress, City, Zip, State, Country</li>
                        </ul>
                        <p>
                          Please update your account & billing details using the link
                          bellow.
                        </p>
                      </div>
                    )}
                    <u>
                      <Link to="/account">Edit account & billing details</Link>
                    </u>

                    {!hasOutOfStock ? (
                      <div>
                        <hr />
                        <h4 className="my-3">Payment:</h4>

                        <div className="mb-4">
                          <p className="mt-3 font-weight-bold">
                            Select mode of payment <span className="text-danger">*</span>
                          </p>

                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="customRadio1"
                              name="paymentOption"
                              onChange={this.onChange}
                              value="Paypal"
                              className="custom-control-input"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customRadio1"
                            >
                              Paypal
                            </label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="customRadio2"
                              name="paymentOption"
                              onChange={this.onChange}
                              value="Bank Transfer"
                              className="custom-control-input"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customRadio2"
                            >
                              Bank Transfer
                            </label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="customRadio3"
                              name="paymentOption"
                              onChange={this.onChange}
                              value="Cash On Delivery"
                              className="custom-control-input"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customRadio3"
                            >
                              Cash On Delivery
                            </label>
                          </div>

                          <p className="mt-4 font-weight-bold">Order notes (optional)</p>
                          <textarea
                            type="radio"
                            className="form-control"
                            name="customerNotes"
                            onChange={this.onChange}
                            placeholder="Enter a special note to seller, instructions, etc. "
                          ></textarea>
                        </div>

                        <hr />

                        {paymentOption === 'Paypal' ? (
                          <PaypalButton
                            client={PAYPAL_CLIENT}
                            env={PAYPAL_ENV}
                            commit={true}
                            currency={settings.currencyCode}
                            total={
                              cart.length
                                ? cart
                                    .reduce(
                                      (sum, i) =>
                                        (sum +=
                                          i.quantity * i.purchasePrice +
                                          i.quantity * i.shippingValue),
                                      0
                                    )
                                    .toFixed(2)
                                : 0
                            }
                            onSuccess={onSuccess}
                            onError={onError}
                            onCancel={onCancel}
                          />
                        ) : paymentOption === 'Bank Transfer' ||
                          paymentOption === 'Cash On Delivery' ? (
                          <button
                            disabled={isInvalid}
                            onClick={() => this.onPlaceOrder()}
                            className="btn btn-dark btn-lg btn-block rounded-0 px-5 py-2 f-15"
                          >
                            Place Order
                          </button>
                        ) : (
                          <em className="text-danger">
                            Please select mode of payment to proceed
                          </em>
                        )}
                      </div>
                    ) : (
                      <div>
                        <hr />
                        <em className="text-danger">
                          There are items in your cart that are currently out of stock,
                          please remove them to proceed with the payment.
                        </em>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {error && <div>{error.message}</div>}
      </div>
    )
  }
}

const condition = (authUser) => !!authUser // "!!authUser" is shorthand for: "authUser != null"

/* We use compose() because each higher order component bellow dont depend on each other
   so instead of doing:
		withRouter(withFirebase(Checkout));
   we just organize it like bellow:
*/
const CheckoutPage = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(Checkout)

export default CheckoutPage
