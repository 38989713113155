import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import * as ROUTES from '../../../constants/routes'

class AdminSettingsNavigation extends Component {
  componentDidMount() {
    document.title = 'Admin - General Settings'
  }

  render() {
    return (
      <div>
        <ul className="nav nav-tabs mb-4">
          <li className="nav-item">
            <NavLink to={ROUTES.ADMIN_SETTINGS_GENERAL} className="nav-link">
              General
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={ROUTES.ADMIN_SETTINGS_PRODUCT} className="nav-link">
              Product
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={ROUTES.ADMIN_SETTINGS_CHECKOUT} className="nav-link">
              Checkout
            </NavLink>
          </li>
        </ul>
      </div>
    )
  }
}

export default AdminSettingsNavigation
