import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { withFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'
import * as ROLES from '../../constants/roles'

const SignInPage = () => (
  <div className="container-fluid py-4" style={{ backgroundColor: '#f2f2f2' }}>
    <div className="row">
      <div className="col-xl-4 col-sm-6 offset-xl-4 offset-sm-3">
        <div className="card mb-3">
          <div className="card-body">
            <SignInGoogle />
          </div>
        </div>
      </div>
    </div>
  </div>
)

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/account-exists-with-different-credential'

const ERROR_MSG_ACCOUNT_EXISTS = `
	An account with an E-Mail address to
	this social account already exists. Try to login from
	this account instead and associate your social accounts on
	your personal account page.
`

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
}

class SignInFormBase extends Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }

  componentDidMount() {
    document.title = 'Sign In'
  }

  onSubmit = (event) => {
    const { email, password } = this.state

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE })
        this.props.history.push(ROUTES.ACCOUNT)
      })
      .catch((error) => {
        this.setState({ error })
      })

    event.preventDefault()
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { email, password, error } = this.state

    const isInvalid = password === '' || email === ''

    return (
      <form onSubmit={this.onSubmit}>
        <div className="form-group">
          <label>Email Address</label>
          <input
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            name="password"
            value={password}
            onChange={this.onChange}
            type="password"
            autoComplete="off"
            className="form-control"
          />
        </div>

        <button disabled={isInvalid} type="submit" className="btn btn-block btn-dark">
          Sign In
        </button>

        {error && (
          <div className="alert alert-danger my-3" role="alert">
            {error.message}
          </div>
        )}
      </form>
    )
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props)

    this.state = { error: null }
  }

  onSubmit = (event) => {
    this.props.firebase
      .doSignInWithGoogle()
      .then((socialAuthUser) => {
        if (socialAuthUser.additionalUserInfo.isNewUser) {
          // Create a user in your Firebase Realtime Database too
          return this.props.firebase.user(socialAuthUser.user.uid).set(
            {
              firstName: socialAuthUser.user.displayName,
              email: socialAuthUser.user.email,
              roles: [ROLES.CUSTOMER],
              createdAt: Date.now(),
            },
            { merge: true } // If the document already exists, its content will be overwritten. If the document doesn’t exist, it will be created.
          )
        }
      })
      .then(() => {
        this.setState({ error: null })
        this.props.history.push(ROUTES.ACCOUNT)
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS
        }
        this.setState({ error })
      })

    event.preventDefault()
  }

  render() {
    const { error } = this.state

    return (
      <form onSubmit={this.onSubmit}>
        <button
          type="submit"
          className="btn btn-block text-white border-0 mb-1"
          style={{ backgroundColor: '#dd453a' }}
        >
          Sign In with Google
        </button>

        {error && (
          <div className="alert alert-danger my-3" role="alert">
            {error.message}
          </div>
        )}
      </form>
    )
  }
}

/* We use compose() because each higher order component bellow dont depend on each other
   so instead of doing:
		withRouter(withFirebase(SignInFormBase));
   we just organize it like bellow:
*/
const SignInForm = compose(withRouter, withFirebase)(SignInFormBase)

const SignInGoogle = compose(withRouter, withFirebase)(SignInGoogleBase)

export default SignInPage

export { SignInForm, SignInGoogle }
